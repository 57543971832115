import { TipoDeImposto, RegimeFiscal, TipoDeOperacaoFiscal } from '@/models'
import { CST, Imposto } from '@/models/fiscal/Imposto'
import { displayPercentual } from '../formatadores'
import { CodigoEDescricao } from '@/models/shareds/CodigoEDescricao'
import {
	FindICMSUseCase,
	FindIPIUseCase,
	FindPISUseCase,
	FindCOFINSUseCase,
	FindCSOSNSUseCase,
} from '@/usecases'

export const sortImpostos = (impostoA: Imposto, impostoB: Imposto) => {
	const [pontuacaoA, pontuacaoB] = [
		pontuarOrdemDoImposto(impostoA),
		pontuarOrdemDoImposto(impostoB),
	]
	return pontuacaoB - pontuacaoA
}

export const pontuarOrdemDoImposto = (imposto: Imposto) => {
	const ordem: TipoDeImposto[] = ['ICMS', 'PIS', 'COFINS', 'IPI']
	return ordem.reverse().indexOf(imposto.tipoDeImposto)
}

export const displayImposto = (imposto: Imposto) => {
	const codCsts = imposto.csts.map(({ cst }) => cst.codigo)
	return `${displayPercentual(imposto.aliquota)}${codCsts ? ' CSTs ' + `${codCsts}` : ''}`
}


export const displayCFOPs = (tiposDeOperacoesFiscais: TipoDeOperacaoFiscal[]) => 
	tiposDeOperacoesFiscais
		.filter(tipo => !!tipo.cfop)
		.map(tipo => tipo.cfop.codigo)
		.toString()
		.replace(',', ' | ')

export const sortByCodigo = (
	cst1: CodigoEDescricao,
	cst2: CodigoEDescricao,
) => {
	if (parseInt(cst1.codigo) < parseInt(cst2.codigo)) {
		return -1
	}
	if (parseInt(cst1.codigo) > parseInt(cst2.codigo)) {
		return 1
	}
	return 0
}

export const listaDeRegimesFiscais: RegimeFiscal[] = [
	'MEI',
	'Simples Nacional',
	'Simples Nacional excesso de receita',
	'Lucro Presumido',
	'Lucro Real',
]


export const findCSTs = async (tipo: TipoDeImposto): Promise<CST[]> => {
	switch (tipo) {
		case 'ICMS':
			return (await new FindICMSUseCase().find()).content.map<CST>(cst => ({ ...cst, tipo }))
		case 'PIS':
			return (await new FindPISUseCase().find()).content.map<CST>(cst => ({ ...cst, tipo }))
		case 'COFINS':
			return (await new FindCOFINSUseCase().find()).content.map<CST>(cst => ({ ...cst, tipo }))
		case 'IPI':
			return (await new FindIPIUseCase().find()).content.map<CST>(cst => ({ ...cst, tipo }))
		case 'CSOSN':
			return (await new FindCSOSNSUseCase().find()).content.map<CST>(cst => ({ ...cst, tipo }))
		default:
			return []
	}
}